<template>
	<v-badge v-if="task.number_of_attachments > 0" color="primary" overlap right>
		<template v-slot:badge>
			<span v-text="task.number_of_attachments" />
		</template>
		<v-tooltip top>
			<template v-slot:activator="{ on }">
				<v-icon v-if="task.number_of_attachments > 0" class="pr-0" v-on="on">attach_file</v-icon>
			</template>
			<span v-t="'tasks.documents_btn_exists'" />
		</v-tooltip>
	</v-badge>
</template>

<script>
export default {
	name: 'TasksManagerTaskDocumentsIcon',
	props: {
		task: {
			required: true,
			type: Object
		}
	}
}
</script>
